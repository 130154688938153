.video-footer {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
  grid-template-columns: 1fr 3fr 1fr;
}

.video-footer-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.video-footer-info{
  color: white;
  display: flex;
  align-items: center;
  margin: 0 20px;
  hr{
    height: 20px;
    outline: none;
    border: 0;
    width: 1px;
    margin: 0 5px;
    background: white;
  }
}

.chat-button{
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #012555 !important;
  width: 50px !important;
  height: 50px !important;
  > *{
    font-size: 20px !important;
  }
 
}