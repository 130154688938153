.screen-share-button {
  font-size: 30px;
  color: #fff;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #012555 !important;
  width: 50px !important;
  height: 50px !important;
  &.started-share{
    color: white;
  }
  > *{
    font-size: 20px !important;
  }
}