.microphone-button {
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #012555 !important;
  width: 50px !important;
  height: 50px !important;
  > *{
    font-size: 20px !important;
  }
 
}
.microphone-dropdown-button {
  // border-radius: 20px;
  border: 1px solid #fff;
  // width: 70px;
  display: flex;
  justify-content: center;
  border-color: #012555 !important;
  width: 50px !important;
  height: 50px !important;
  background: rgb(47, 128, 236);
  border-radius: 50%;
  align-items: center;
  > .ant-btn {
    font-size: 20px;
    color: #fff;
    display: flex;
    height: 60px !important;
    align-items: center;
    border-width: 0 !important;
    padding: 0;
    margin-right: 5px;
    &:hover {
      color: #40a9ff;
    }
  }
  > .ant-btn[disabled]{
    background: transparent;
    color: #999;
  }
  > .ant-dropdown-trigger {
    width: 10px !important;
    margin-right: 0;
  }
  .ant-btn-icon-only.ant-btn-lg>*{
    font-size: 12px;
  }
}
.microphone-menu {
  border: 1px solid #747487;
  background: rgba(0, 0, 0, 0.9) !important;
  .ant-dropdown-menu-item {
    padding: 10px 30px;
    color: #ccc;
    position: relative;
    .anticon-check {
      position: absolute;
      left: 10px;
      top: 15px;
    }
    &:hover {
      color: #40a9ff;
    }
  }
  .ant-dropdown-menu-item-group-title {
    color: #fff;
    font-weight: 700;
  }
}

